import { Resource as ResourceType } from '../types';

export const favoritesEntity: ResourceType = {
  name: 'favorites_entities',
  label: 'resource.favorites_entity.label',
  hideResource: true,
  attributes: [
    {
      key: 'id',
      label: 'resource.favorites_entity.field.id.label',
      type: 'text',
    },
    {
      key: 'entity_id',
      label: 'resource.favorites_entity.field.entity_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'list_id',
      label: 'resource.favorites_entity.field.list_id.label',
      type: 'text',
    },
    {
      key: 'company_leader_id',
      label: 'resource.favorites_entity.field.company_leader_id.label',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'company_leader_company_name',
      label:
        'resource.favorites_entity.field.company_leader_company_name.label',
      type: 'text',
    },
    {
      key: 'company_leader_job_name',
      label: 'resource.favorites_entity.field.company_leader_job_name.label',
      type: 'text',
    },
    {
      key: 'default_pool',
      label: 'resource.favorites_entity.field.default_pool.label',
      type: 'boolean',
    },
    {
      key: 'created_at',
      label: 'resource.favorites_entity.field.created_at.label',
      type: 'date',
    },
  ],
};
