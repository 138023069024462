import { Config as ConfigType } from './types';
import { user } from './s30/user';
import { expert } from './s30/expert';
import { client } from './s30/client';
import { company } from './s30/company';
import { mission } from './s30/mission';
import { invitation } from './s30/invitation';
import { candidate } from './s30/candidate';
import { candidacy } from './s30/candidacy';
import { proposal } from './s30/proposal';
import { quotation } from './s30/quotation';
import { charge } from './s30/charge';
import { milestone } from './s30/milestone';
import { contract } from './s30/contract';
import { contractMilestone } from './s30/contractMilestone';
import { invoice } from './s30/invoice';
import { recommendation } from './s30/recommendation';
import { legalInformation } from './s30/legalInformation';
import { cgu } from './s30/cgu';
import { match } from './s30/match';
import { document } from './s30/document';
import { documentsCompanies } from './s30/documentsCompanies';
import { subCategory } from './s30/subCategory';
import { documentFile } from './s30/documentFile';
import { tag } from './s30/tag';
import { favoritesEntity } from './s30/favoritesEntity';

export const config: ConfigType = {
  login: 'password',
  hash: '',
  locale: 'fr',
  name: 's30',
  dashboard: true,
  theme: {
    primary: '#5465fb',
    secondary: '#c0c0c8',
    info: '#f8dddd',
  },
  defaultCountry: 'fr',
  preferredCountries: ['fr'],
  resources: [
    expert,
    client,
    user,
    subCategory,
    company,
    mission,
    invitation,
    candidate,
    candidacy,
    proposal,
    quotation,
    charge,
    milestone,
    contract,
    contractMilestone,
    invoice,
    recommendation,
    legalInformation,
    document,
    documentFile,
    documentsCompanies,
    cgu,
    match,
    tag,
    favoritesEntity,
  ],
};

export default config;
