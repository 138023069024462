import { Resource as ResourceType } from '../types';

export const expert: ResourceType = {
  name: 'experts',
  label: 'resource.experts.label',
  hideResource: false,
  attributes: [
    {
      key: 'id',
      label: 'resource.users.field.id.label',
      type: 'text',
    },
    {
      key: 'first_name',
      label: 'resource.users.field.first_name.label',
      type: 'text',
    },
    {
      key: 'last_name',
      label: 'resource.users.field.last_name.label',
      type: 'text',
    },
    {
      key: 'company_name',
      label: 'resource.users.field.company_name.label',
      type: 'text',
    },
    {
      key: 'email',
      label: 'resource.users.field.email.label',
      type: 'email',
    },
    {
      key: 'company_id',
      label: 'resource.users.field.company_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'phone',
      label: 'resource.users.field.phone.label',
      type: 'phone',
    },
    {
      key: 'linkedin_vanity_name',
      label: 'resource.users.field.linkedin_vanity_name.label',
      type: 'url',
    },
    {
      key: 'blocked',
      label: 'resource.users.field.blocked.label',
      type: 'boolean',
    },
    {
      key: 'administrator',
      label: 'resource.users.field.administrator.label',
      type: 'boolean',
    },
    {
      key: 'created_at',
      label: 'resource.users.field.created_at.label',
      type: 'date',
    },
    {
      key: 'cgu_accepted_at',
      label: 'resource.users.field.cgu_accepted_at.label',
      type: 'date',
    },
    {
      key: 'anonymized',
      label: 'resource.users.field.anonymized.label',
      type: 'boolean',
    },
    {
      key: 'job_name',
      label: 'resource.users.field.job_name.label',
      type: 'text',
    },
    {
      key: 'filled',
      label: 'resource.users.field.filled.label',
      type: 'boolean',
    },
    {
      key: 'is_registered',
      label: 'resource.users.field.is_registered.label',
      type: 'boolean',
    },
    {
      key: 'relevance_current_step_name',
      label: 'resource.users.field.relevance_current_score.label',
      type: 'text',
    },
    {
      key: 'fo_link',
      label: 'resource.proposals.field.fo_link.label',
      type: 'frontOfficeLink',
      externalLink: {
        source: 'id',
        path: 'experts',
      },
    },
    {
      key: 'company_cv_download_url',
      label: 'resource.companies.field.cv_download_url.label',
      type: 'file',
    },
    {
      key: 'company_kind',
      label: 'resource.companies.field.kind.label',
      type: 'select',
      choices: [
        {
          id: 'unknown',
          name: 'resource.companies.field.kind.choices.unknown.label',
        },
        {
          id: 'esn',
          name: 'resource.companies.field.kind.choices.esn.label',
        },
        {
          id: 'recruitment_candidate',
          name: 'resource.companies.field.kind.choices.recruitment_candidate.label',
        },
        {
          id: 'freelance',
          name: 'resource.companies.field.kind.choices.freelance.label',
        },
        {
          id: 'design_office',
          name: 'resource.companies.field.kind.choices.design_office.label',
        },
        {
          id: 'agency',
          name: 'resource.companies.field.kind.choices.agency.label',
        },
        {
          id: 'major_company',
          name: 'resource.companies.field.kind.choices.major_company.label',
        },
        {
          id: 'startup',
          name: 'resource.companies.field.kind.choices.startup.label',
        },
        {
          id: 'consulting',
          name: 'resource.companies.field.kind.choices.consulting.label',
        },
        {
          id: 'smes',
          name: 'resource.companies.field.kind.choices.smes.label',
        },
      ],
    },
  ],
  pages: {
    list: {
      hasCreateButton: true,
      hasEditButton: true,
      editAndCreateBasePath: '/users',
      exportKindType: 'expert',
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      bulkActionButtons: [
        {
          name: 'validate',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.validate.label',
          values: {
            validated: true,
          },
        },
        {
          name: 'unvalidate',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.unvalidate.label',
          values: {
            validated: false,
          },
        },
        {
          name: 'block',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.block.label',
          icon: 'Lock',
          values: {
            blocked: true,
          },
        },
        {
          name: 'unblock',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.unblock.label',
          icon: 'LockOpen',
          values: {
            blocked: false,
          },
        },
        {
          name: 'anonymize',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.anonymize.label',
          values: {},
          extraUrl: '/anonymize',
          icon: 'VisibilityOff',
          // Following settings are only useful for update with confirmation
          withConfirmation: true,
          confirmation: {
            content: 'modal.bulk_anonymize.content',
          },
        },
      ],
      filters: [
        {
          source: 'blocked',
        },
        {
          source: 'administrator',
        },
        {
          source: 'company_id',
          type: 'reference',
          child: {
            type: 'autocomplete',
            // Options to pass to ReferenceInput
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
        {
          source: 'company_kind',
        },
      ],
      fields: [
        {
          source: 'first_name',
        },
        {
          source: 'last_name',
        },
        {
          source: 'email',
        },
        {
          source: 'phone',
        },
        {
          source: 'company_kind',
        },
        {
          source: 'job_name',
        },
        {
          source: 'linkedin_vanity_name',
          icon: 'LinkedIn',
        },
        {
          source: 'fo_link',
          sortable: false,
          icon: 'AccountCircle',
        },
        {
          source: 'company_id',
          link: false,
          sortable: false,
          label: 'resource.companies.field.competencies_file.label',
          key: 'download',
          type: 'buttons',
          extensions: [
            {
              key: 'competenciesFiles',
              params: {
                formats: ['pdf', 'docx'],
                resourceToFetch: 'companies',
              },
            },
          ],
        },
        {
          source: 'company_cv_download_url',
          title: 'cv',
          sortable: false,
        },
        {
          source: 'relevance_current_step_name',
        },
        {
          source: 'blocked',
        },
        {
          source: 'filled',
          sortable: false,
        },
        {
          source: 'is_registered',
        },
        {
          source: 'cgu_accepted_at',
        },
        {
          source: 'created_at',
        },
      ],
    },
    edit: {
      left: {
        tabs: [],
      },
      right: [],
    },
  },
};
